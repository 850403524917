.createPost {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.2);
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
}

.createPost:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.1);
}

.post-header h4 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

#post-btn {
    border: none;
    font-size: 14px;
    color: #2eb9f0;
    font-weight: bolder;
    cursor: pointer;
    background: none;
    transition: color 0.3s ease-in-out;
}

#post-btn:hover {
    color: #1e88c7;
}

.main-div {
    border-top: 1px solid rgba(128, 128, 128, 0.1);
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#output {
    width: 300px;
    border: none;
    outline: none;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.details {
    border-top: 1px solid rgba(128, 128, 128, 0.1);
    padding: 20px 0;
}

textarea {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    margin: 10px 0;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
}

textarea:focus {
    background-color: #fff;
}

.media-preview {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    overflow: hidden; /* Ensure that any overflowing content is hidden */
}

.media-preview img,
.media-preview video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-header {
    display: flex;
    align-items: center;
}

.card-pic img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-header h5 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

@media screen and (max-width: 800px) {
    .createPost {
        max-width: 90%;
        padding: 15px;
    }

    .post-header {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 15px;
    }

    #post-btn {
        align-self: flex-end;
        margin-top: 10px;
    }

    #output {
        width: 100%;
    }

    textarea {
        width: 100%;
    }
}
