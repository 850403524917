/* search.css */
.search-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-form {
    display: flex;
    align-items: center;
}

.search-form input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
}

.search-form input[type="text"]:focus {
    border-color: #007bff;
}

.search-results {
    margin-top: 20px;
}

.search-result-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.search-result-item img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-link {
    color: #333;
    text-decoration: none;
    font-weight: bold;
}
.user-link p{
    font-size: xx-small;
    margin: 0px -20px;
    padding: 0px 22px 0px;
    display: flex;
}

.user-link:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}
