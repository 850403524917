body{
    width: 100%;
    height: 100%;
}
.signIn{
    background: linear-gradient(white,yellow,orange);
    background-size: cover;
    flex:1;
    /* border: 1px solid white; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginForm{
    border: 1px solid black;
    border-radius: 15px;
    padding: 30px;
       
}
.signUpLogo{
    width: 130px;
    padding: 10px 0px;
    object-fit: contain;
}
.loginForm > div{
    width: 80%;
    margin: auto;
}
#login-btn{
    background: blue;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 12px;
    padding: 5px 10px;
    width: 80%;
    cursor: pointer;
    
}
#login-btn:hover{
    background: white;
    color: black;
    font-weight: 600;
    border: none;
    border-radius: 12px;
    padding: 5px 10px;
    width: 80%;
    cursor: pointer;
}

.form2{
    border: 1px solid black;
    padding: 30px;
    border-radius: 15px;
    margin: 4px;
}