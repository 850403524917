.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.card {
    width: 500px;
    
    margin: 0px auto;
    border: 1px solid rgba(0, 0, 0, 0.213);
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}


.card-header {
    display: flex;
    align-items: center;
    padding: 0px 2px;
    border-bottom: 1px solid #cecece;
}

.card-header > h5 {
    display: inline-block;
    /* text-decoration: none; */
    margin: 1px;
    padding: 10px 10px;
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
}
h5 a{
    text-decoration: none;
}

.card-pic img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
}

.card-media {
    width: 100%;
    min-height: 300px; /* Set a minimum height for the media container */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.card-media img,
.card-media video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the media covers the container */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.card-content {
    text-align: left;
    line-height: 1.5;
    padding: 10px;
}

.card-content p {
    margin: 5px 0;
}

.add-comment {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.add-comment input {
    flex-grow: 1;
    margin: 0;
    outline: none;
    border: none;
    padding: 10px;
    font-size: 14px;
}

.add-comment .comment {
    width: 70px;
    height: 35px;
    border-radius: 5px;
    border: none;
    background: none;
    margin: 0 5px;
    color: rgb(46, 185, 240);
    font-weight: bold;
    cursor: pointer;
}

.material-symbols-outlined {
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
}

.material-symbols-outlined-red {
    color: red;
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.comment-section {
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 0 0 5px 5px;
}

.comm {
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding: 5px;
    border-bottom: 1px solid #f0f0f0;
}

.commenter {
    font-weight: bold;
    margin-right: 5px;
    color: #333;
}

.commentText {
    color: #555;
    font-size: 14px;
}

.comm:hover {
    background-color: #f9f9f9;
}