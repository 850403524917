.outer-range {
    display: flex;
    height: 88vh;
    overflow: hidden;
}

.layer1,
.layer2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
}

.layer1.hidden,
.layer2.hidden {
    display: none;
}

.user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.profile-pic img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.name {
    font-size: 1.2rem;
    margin: 0.5rem 0;
}

.my-account {
    font-size: 0.9rem;
    color: #555;
}

.messages-title {
    padding: 0.5rem;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.conversations {
    flex: 1;
    overflow-y: auto;
}

.conversation {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.conversation:hover {
    background-color: #e0e0e0;
}

.conversation .profile-pic img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.conversation .name {
    flex: 1 1;
    font-size: 135%;
    margin-left: .5rem;
}

.online-status {
    color: green;
}

.user-message {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ccc;
}

.user-message .profile-pic img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.user-message .name {
    color: red;
    flex: 1 1;
    font-size: 100%;
    margin-left: .5rem;
    font-weight: revert;
}

.account-status {
    color: green;
    font-size: 70%;
}

.symbols {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.symbols .material-symbols-outlined {
    font-size: 1.5rem;
    cursor: pointer;
}

.msgbox {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    padding: 1rem;
}

.insidemsgbox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.inmsg {
    align-self: flex-start;
    background-color: #e1f5fe;
  
}
.inmsg, .outmsg {
    border-radius: 10px;
    padding: .5rem 1rem;
    font-size: 200%;
}

.outmsg {
    align-self: flex-end;
    background-color: #c8e6c9;
   
}

.message-container {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-top: 1px solid #ccc;
    background-color: #f1f1f1;
}

.message-container textarea {
    background-color: #f9f9f9;
    border: none;
    border-radius: 10px;
    box-shadow: inset 0 1px 3px #0000001a;
    font-size: 200%;
    margin: 10px 0;
    outline: none;
    padding: 10px;
    resize: none;
    transition: background-color .3s ease-in-out;
    /* width: 100%; */
}

.message-container .material-symbols-outlined {
    font-size: 2rem;
    cursor: pointer;
    margin-left: 0.5rem;
    color: #007bff;
}

.back-button {
    font-size: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    color: #007bff;
    border: none;
    background: none;
    margin-left: 0.5rem;
}

/* @media (max-width: 768px) {
    .outer-range {
        flex-direction: column;
    }

    .layer1,
    .layer2 {
        width: 100%;
        flex: none;
        display: none;
    }

    .layer1.visible,
    .layer2.visible {
        display: flex;
    }
} */
@media screen and (max-width: 980px) {
    .layer2 {
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }

    .profile-pic {
        /* height: -moz-fit-content; */
        height: fit-content;
        margin: 0px 0%;
        padding: 0px;
    
    }
    .user-message .name {
        color: red;
        flex: 1 1;
        display: -webkit-inline-box;
        font-size: 100%;
        font-weight: revert;
        margin-left: .5rem;
    }
    .account-status {
        color: green;
        font-size: 70%;
        margin: 7%;
        /* display: flex; */
    }

    .user-message {
        align-items: center;
        background-color: #f9f9f9;
        border-bottom: 1px solid #ccc;
        display: flex;
        padding: 10px 0;
        position: fixed;
        top: 8%;
        width: 100%;
        z-index: 10;
        height: 8%;
    }

    .msgbox {
        flex: 1 1;
        overflow-y: auto;
        padding-bottom: 34%;
        padding-top: 34%;
    }
    /* .msgbox {
        display: flex;
        flex: 1 1;
        flex-direction: column-reverse;
        overflow-y: auto; 
        padding: 3rem;
    }  */
    

    .message-container {
        background-color: #f1f1f1;
        border-top: 1px solid #ccc;
        bottom: 1%;
        display: flex;
        height: 6%;
        padding: 1%;
        position: fixed;
        width: 100%;
        margin: 20% 0% 18% 0%;
    }

    .message-container textarea {
        flex: 1 1;
        font-size: 107%;
        /* height: 30%; */
        resize: none;
    }
    .symbols .material-symbols-outlined {
        cursor: pointer;
        font-size: 130%;
        margin-left: -0.5rem;
        margin-right: 0px;
    
    }
    .symbols.material-symbols-outlined {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .insidemsgbox {
        padding: 1rem;
        overflow-y: auto;
    }

    .inmsg, .outmsg {
        padding: 0.5rem;
        margin: 0.5rem 0;
        border-radius: 10px;
        max-width: 80%; /* Adjusted width for better readability */
    }

    .inmsg {
        
        /* align-self: flex-end; */
        /* background-color: #0084ff; */
        /* color: #fff; */
        font-size: 94%;
        background-color: #e0e0e0;
        align-self: flex-start;
        /* font-size: 190%; */
    }

    .outmsg {
        align-self: flex-end;
        font-size: 94%;
        background-color: #0084ff;
        color: #fff;
    }
    .user-profile {
        margin: 10% 0%;
        align-items: center;
        background-color: #fff;
        box-shadow: 0 1px 2px #0000001a;
        display: flex;
        top: 47px;
        flex-direction: column;
        /* padding: 1rem; */
    }
}