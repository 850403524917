
.signUp{
    background: linear-gradient(white,yellow,orange);
    background-size: cover;
    flex:1;
    padding: 25px;
    /* border: 1px solid white; */
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.form{
    border: 1px solid black;
    border-radius: 15px;
    padding: 30px;
    
    
    
    
}
.signUpLogo{
    width: 130px;
    padding: 10px 0px;
    object-fit: contain;
}
.loginPara{
    color: gray;
    font-size: 20px;
    line-height: 22px;
    margin: 0px 40px 20px 40px;
}
input{
    padding: 5px;
    margin: 3px;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;

}
.form >div{
    width: 80%;
    margin: auto;
}
#submit-btn{
    background: blue;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 12px;
    padding: 5px 10px;
    width: 80%;
    cursor: pointer;
    
}
#submit-btn:hover{
    background: white;
    color: black;
    font-weight: 600;
    border: none;
    border-radius: 12px;
    padding: 5px 10px;
    width: 80%;
    cursor: pointer;
}
.form2{
    border: 1px solid black;
    padding: 30px;
    border-radius: 15px;
    margin: 4px;
}